import React from 'react'
import { RESOURCES } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const HoteltieUps = () => {
  return (
    <div>
      <Layout>
        <SEO title="Hotel tie Ups" />
        <PageStructure title="Hotel tie Ups" desc="TESTIG" mode={RESOURCES} />
      </Layout>
    </div>
  )
}

export default HoteltieUps
